import React from 'react'
import SEOTools from '../components/SEOTools'
import { DemoSelectorContainer, SelectorLink } from '../components/DemoSelector'
import CountrySelector from '../components/CountrySelector'

/**
 * @returns {JSX.Element}
 * @constructor
 */
const IndexPage = () => {
    return (
        <>
            <SEOTools title='Payout' />
            <section className='full-screen-demo-section'>
                <iframe
                    title='Payout'
                    className='w-100 border-0 full-screen-demo'
                    src='https://framer.com/embed/Siru-Airtime-Payout--7shFgUMcHndMnmrWkM6Y/HsC_NoMmI'
                />
            </section>

            <DemoSelectorContainer rowNumber={1}>
                <SelectorLink to={'/'} name='Pay with Siru' />
                <SelectorLink to={'/wallet'} name='Wallet (check your balance)' />
                <SelectorLink to={'/payout'} name='Payout' />
            </DemoSelectorContainer>

            <CountrySelector enabledCountries={[]} />
        </>
    )
}

export default IndexPage
